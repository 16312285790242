import React from 'react'

import './HalfImage.css'

class HalfImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      wrapperClasses: this.props.background ? 'full-primary-dark' : '',
      imageClass: this.props.icon ? 'icon' : 'image',
    }
  }

  render() {
    return (
      <div className={`half-image ${this.state.wrapperClasses}`}>
        <div className="container">
          <div className="row">
            {(this.props.imagePosition === 'left' ||
              (this.props.imagePosition === 'right' &&
                this.props.mobileFlip)) && (
              <div
                className={`six columns ${this.state.imageClass} ${
                  this.props.imagePosition === 'right' && this.props.mobileFlip
                    ? 'mobile-only'
                    : this.props.mobileFlip
                    ? 'desktop-only'
                    : ''
                }`}
                style={{ backgroundImage: `url(${this.props.image})` }}
              ></div>
            )}

            <div className="six columns text">{this.props.text}</div>

            {(this.props.imagePosition === 'right' ||
              (this.props.imagePosition === 'left' &&
                this.props.mobileFlip)) && (
              <div
                className={`six columns ${this.state.imageClass} ${
                  this.props.imagePosition === 'left' && this.props.mobileFlip
                    ? 'mobile-only'
                    : this.props.mobileFlip
                    ? 'desktop-only'
                    : ''
                }`}
                style={{ backgroundImage: `url(${this.props.image})` }}
              ></div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default HalfImage
